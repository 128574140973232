import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import image1 from './inognicat-web.png';
import image2 from './samourai-cat-web.png';
import image3 from './chatbuy.png';



const images = [
  { src: image3, alt: 'Image 1' },
  { src: image3, alt: 'Image 2' },
  { src: image3, alt: 'Image 3' },
  { src: image3, alt: 'Image 4' },

];

const Image = styled.img<{ top: number; left: number }>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: 80px;
  height: 80px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const FloatingImages: React.FC = () => {
  const [positions, setPositions] = useState(
    images.map(() => ({
      top: Math.random() * (window.innerHeight - 600), // Début plus bas
      left: Math.random() * (window.innerWidth - 350),
      directionX: Math.random() > 0.5 ? 1 : -1,
      directionY: Math.random() > 0.5 ? 1 : -1,
    }))
  );

  const animationFrameId = useRef<number | null>(null);

  const updatePositions = () => {
    setPositions((prevPositions) =>
      prevPositions.map((pos) => {
        let newTop = pos.top + pos.directionY * 1.5; // Ajustez la vitesse si nécessaire
        let newLeft = pos.left + pos.directionX * 1.5;

        if (newTop <= 0 || newTop >= window.innerHeight - 300) {
          pos.directionY *= -1;
        }

        if (newLeft <= 0 || newLeft >= window.innerWidth - 300) {
          pos.directionX *= -1;
        }

        return {
          ...pos,
          top: newTop,
          left: newLeft,
        };
      })
    );
    animationFrameId.current = requestAnimationFrame(updatePositions);
  };

  useEffect(() => {
    animationFrameId.current = requestAnimationFrame(updatePositions);

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  return (
    <div>
      {images.map((image, index) => {
        const { top, left } = positions[index];
        return (
          <Image
            key={index}
            src={image.src}
            alt={image.alt}
            top={top}
            left={left}
            className="floating-image"
          />
        );
      })}
    </div>
  );
};

export default FloatingImages;
