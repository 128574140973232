import { faTelegram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MoveDirection,
  OutMode,
  type Container,
  type ISourceOptions,
} from "@tsparticles/engine";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { useEffect, useMemo, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import FloatingImages from "./FloatingImages";
import "./Home.css";
import gif from './fonts/données.gif'
import dexscreenerIcon from './fonts/dexscreener.png';
import dollarsIcon from './fonts/dollars.png';


const concept1 = (
  <>
    <title>📊How it works ?</title>
    <br></br>
    1. Pump fun launch 🚀<br></br>
    We launch on pump fun and send the link to the group.<br></br>
    <br></br>
    2. Get rid of bots 🤖<br></br>
    Do not buy in the first 3 mins. We use it to get rid of « taking instant
    profit » bots<br></br>
    <br></br>
    3. Buy buy buy 💹<br></br>
    Channels members ape in.<br></br>
    We launch micro-buying strategy using custom bots to always be on home page.
    <br></br>
    <br></br>
    4. Radyium launch & first profits💸<br></br>
    Once launched on Raydium, coin do an average of 200% in the first 5min. We
    advise to sell 30% of your bag.<br></br>
    <br></br>
    5. Grow 📈<br></br>
    Marketing is launched and project is growing. Website is launched.
    <br></br>
    <br></br>
    💅 What is planned for marketing :<br></br>
    <br></br>
    We buy 5% at launch🚀, then<br></br>
    <br></br>- We sell 1% at 50k to pay for Dextscreener update
    <br></br>- We sell 1% at 140K to pay for callers<br></br>- We sell 2% at
    300k to pay for dextools update,ads .<br></br>- We sell le last 1% between
    500k and 1M to allow the community to grow<br></br>
    <br></br>
    Most importantly you need a community who embraces the life of a Jeet and
    learns to take small <br></br>~profits on the way up and never dump your
    whole bag. The goal isn't 10x, the goal is to make profit every launch.
    <br></br>
  </>
);

const concept2 = (
  <>
    FLIPCAT is a community focused cryptocurrency created by one of the
    original Dogecoin shibes from 2013. <br></br> It was created for one
    purpose, to create a new and fun community just like the original Dogecoin
    community.{" "}
  </>
);

const concept3 = (
  <>
    FLIPCAT tipping is supported in telegram <br></br>
    Additionally, FLIPCAT tipping is supported on twitter! Send a direct
    message to @FLIPCATPPTC on Reddit to get started.
  </>
);

const Home: React.FC = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    console.log(container);
  };

  const sectionsRef = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const handleLoad = () => {
      sectionsRef.current.forEach((section) => {
        if (section.getBoundingClientRect().top < window.innerHeight) {
          section.classList.add("visible");
        }
      });
    };

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    sectionsRef.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    window.addEventListener("load", handleLoad);

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  const options: ISourceOptions = useMemo(
    () => ({
      background: {
        color: {
          value: "black",
          b: 1,
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#FFD700",
          width: 1,
        },
        links: {
          color: "#ffffff",
          distance: 100,
          enable: true,
          opacity: 0,
          width: 1,
        },
        move: {
          direction: MoveDirection.none,
          enable: true,
          outModes: {
            default: OutMode.out,
          },
          random: false,
          speed: 2,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 50,
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: "star",
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  if (init) {
    return (
      <div className="home">
        <HomeContainer>
          <img src={gif} className="gifsize" alt="Promotional GIF" />
          <SocialSection>
            <SocialButton href="https://www.pump.fun/3jEUgoYKrLDQxT3FC3BbXm9mKpiSYWm14C35Ae8EaF1b">
              <img className="icon" src={dollarsIcon} alt="Buy Icon" width="50" height="50" />
              <ButtonText>BUY</ButtonText>
            </SocialButton>
            <SocialButton href="">
              <img className="icon" src={dexscreenerIcon} alt="Dexscreener Icon" width="50" height="50" />
              <ButtonText> Dexscreener</ButtonText>
            </SocialButton>
            <SocialButton href="https://x.com/MrFlipTheCat" target="_blank">
              <FontAwesomeIcon icon={faTwitter} />
              <ButtonText>Twitter</ButtonText>
            </SocialButton>
            <SocialButton href="https://t.me/flipdatcat" target="_blank">
              <FontAwesomeIcon icon={faTelegram} />
              <ButtonText>Telegram</ButtonText>
            </SocialButton>
          </SocialSection>
          <FloatingImages />
          <h2>
            WELCOME TO FLIPCAT
            <p>YOUR ULTIMATE SPACE FOR MEMES AND COMMUNITY FUN</p>
          </h2>
          {[concept1, concept2, concept3].map((concept, index) => (
            <Section
              key={index}
              ref={(el) => {
                if (el && !sectionsRef.current.includes(el)) {
                  sectionsRef.current.push(el);
                }
              }}
            >
              <SectionText> {concept}</SectionText>
            </Section>
          ))}
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          />
        </HomeContainer>
      </div>
    );
  }
  return <></>;
};


const HomeContainer = styled.div`
  padding: 20px;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  font-size: 0.8em;
  text-transform: uppercase;


  @media (max-width: 768px) {
    padding: 10px;
    font-size: 0.6em;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const Section = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  &.visible {
    opacity: 1;
    transform: translateX(0);
    animation: ${slideIn} 1s ease forwards;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const SectionText = styled.div`
  font-family: 'Press Start 2P', cursive;
  font-size: 1em !important;

  @media (max-width: 768px) {
    font-size: 0.8em !important;
  }
`;


const GIFImage = styled.img`
  margin-bottom: 20px;
  width: 350px;
  @media (max-width: 800px) {
    width: 250;
    margin-bottom: 10px;
    margin-right 30%;
  }
`;

const SocialSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    gap: 10px;
    margin-top: 10px;
  }
`;

const SocialButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border: 3px solid #FFD700;
  border-radius: 10px;
  text-decoration: none;
  font-family: 'Press Start 2P', cursive;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #FFD700;
    color: #000;
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    font-size: 0.8em;
  }
`;

const ButtonText = styled.span`
  margin-top: 5px;
  font-size: 0.8em;

  @media (max-width: 768px) {
    margin-top: 3px;
    font-size: 0.7em;
  }
`;


export default Home;
