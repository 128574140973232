import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #121212 url('path_to_your_background_image.jpg') no-repeat center center fixed;
    background-size: cover;
    font-family: 'Roboto', sans-serif;
    color: white;
  }

  .App {
    text-align: center;
    min-height: 100vh;
  }
`;

export default GlobalStyle;

