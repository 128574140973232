import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Memes from './components/Meme';
import Navbar from './components/Navbar';
import GlobalStyle from './components/GlobalStyle';
import './index.css'

function App() {
  return (
    <Router>
      <GlobalStyle />
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/memes" element={<Memes />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
