import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <h1 className='rainbow-text' >FLIPCAT</h1>
      <div className="links">
        <Link to="/">HOME</Link>
      </div>
    </nav>
  );
}



export default Navbar;

